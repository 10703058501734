import React, { useState, useRef, useEffect, forwardRef } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Paper,
  IconButton,
  Tooltip,
  Slider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getImageUrl } from '../utils/imageUtils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageUpload from './ImageUpload';
import { config } from '../config/config';
import EditEvent from './EditEvent';

const QuillEditor = forwardRef((props, ref) => (
  <ReactQuill
    ref={ref}
    {...props}
  />
));

function EventForm({ initialData, onSubmit, submitButtonText, onGoBack }) {
  const today = new Date().toISOString().split('T')[0];
  
  const [event, setEvent] = useState({
    title: '',
    description: '',
    startTime: '',
    endTime: '',
    minCapacity: '1',
    maxCapacity: '2',
    minGoLiveCapacity: '1',
    earlyBirdPrice: '',
    finalPrice: '',
    prepTime: '1',
    daysOfWeek: [],
    earliestEventDate: today,
    latestEventDate: '',
    flyerImage: null,
    imageFile: null,
    ...initialData
  });
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const quillRef = useRef(null);

  useEffect(() => {
    if (initialData?.flyerImage) {
      setImagePreview(getImageUrl(initialData.flyerImage));
    }
  }, [initialData]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
      setEvent(prev => ({ ...prev, imageFile: file }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check for required fields
    const requiredFields = [
      'title', 
      'description', 
      'minCapacity',
      'maxCapacity',
      'minGoLiveCapacity',
      'startTime',
      'endTime'
    ];
    
    const missingFields = requiredFields.filter(field => {
      const value = event[field];
      return value === null || value === undefined || value === '';
    });
    
    if (missingFields.length > 0) {
      console.error('Missing required fields:', missingFields);
      alert(`Please fill in all required fields: ${missingFields.join(', ')}`);
      return;
    }

    if (typeof onSubmit === 'function') {
      await onSubmit(event);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet',
    'link'
  ];

  const handleImageSelect = (file) => {
    const previewUrl = URL.createObjectURL(file);
    setImagePreview(previewUrl);
    setEvent(prev => ({ 
      ...prev, 
      imageFile: file,
      flyerImage: null
    }));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <ImageUpload 
          onImageSelect={handleImageSelect}
          currentImage={imagePreview || (event.flyerImage ? getImageUrl(event.flyerImage) : null)}
        />
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box sx={{ mb: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <Typography variant="subtitle1">Event Title</Typography>
              <Tooltip title="The name of your event">
                <HelpOutlineIcon color="action" sx={{ cursor: 'help', fontSize: 20 }} />
              </Tooltip>
            </Box>
            <TextField
              fullWidth
              value={event.title || ''}
              onChange={(e) => setEvent({ ...event, title: e.target.value })}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>Event Description</Typography>
          <Paper sx={{ mb: 1, '& .quill': { resize: 'vertical', overflow: 'hidden' } }}>
            <QuillEditor
              value={event.description || ''}
              onChange={(content) => setEvent(prev => ({ ...prev, description: content }))}
              modules={modules}
              formats={formats}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sx={{ pt: 4 }}>
          <Grid container spacing={3}>
            {/* Pricing Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Typography variant="subtitle1">Event Pricing</Typography>
                <Tooltip title="Early bird pricing is available for a limited time before the event goes live. Final pricing is the price of the event after the early bird period ends.">
                  <HelpOutlineIcon color="action" sx={{ cursor: 'help', fontSize: 18 }} />
                </Tooltip>
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  fullWidth
                  type="number"
                  label="Early Bird Price"
                  value={event.earlyBirdPrice || ''}
                  onChange={(e) => setEvent({ ...event, earlyBirdPrice: e.target.value })}
                  InputProps={{
                    startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
                  }}
                />
                <TextField
                  fullWidth
                  type="number"
                  label="Final Price"
                  value={event.finalPrice || ''}
                  onChange={(e) => setEvent({ ...event, finalPrice: e.target.value })}
                  InputProps={{
                    startAdornment: <Typography sx={{ mr: 1 }}>$</Typography>,
                  }}
                />
              </Box>
            </Grid>

            {/* Event Times Section */}
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                <Typography variant="subtitle1">Event Times</Typography>
                <Tooltip title="Set the start and end times for your event">
                  <HelpOutlineIcon color="action" sx={{ cursor: 'help', fontSize: 18 }} />
                </Tooltip>
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  fullWidth
                  type="time"
                  label="Start Time"
                  value={event.startTime || ''}
                  onChange={(e) => setEvent({ ...event, startTime: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  type="time"
                  label="End Time"
                  value={event.endTime || ''}
                  onChange={(e) => setEvent({ ...event, endTime: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
            <Typography variant="subtitle1">Attendees</Typography>
            <Tooltip title="Set the minimum and maximum number of attendees, and the threshold for when the event goes live">
              <HelpOutlineIcon color="action" sx={{ cursor: 'help', fontSize: 18 }} />
            </Tooltip>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              fullWidth
              type="number"
              label="Minimum"
              value={event.minCapacity || ''}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setEvent(prev => ({
                  ...prev,
                  minCapacity: value,
                  minGoLiveCapacity: Math.min(prev.minGoLiveCapacity || value, prev.maxCapacity || value)
                }));
              }}
              sx={{ width: '120px' }}
            />
            
            <Box sx={{ flex: 1, mx: 2 }}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Go Live Capacity: {event.minGoLiveCapacity || event.minCapacity || 0}
                </Typography>
              </Box>
              <Slider
                value={event.minGoLiveCapacity || event.minCapacity || 0}
                min={event.minCapacity || 0}
                max={event.maxCapacity || 100}
                onChange={(_, value) => setEvent(prev => ({
                  ...prev,
                  minGoLiveCapacity: value
                }))}
                disabled={!event.minCapacity || !event.maxCapacity}
              />
            </Box>

            <TextField
              type="number"
              label="Maximum"
              value={event.maxCapacity || ''}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setEvent(prev => ({
                  ...prev,
                  maxCapacity: value,
                  minGoLiveCapacity: Math.min(prev.minGoLiveCapacity || prev.minCapacity || 0, value)
                }));
              }}
              sx={{ width: '120px' }}
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Accordion 
            defaultExpanded={false}
            sx={{
              '& .MuiAccordionSummary-root': {
                bgcolor: 'success.main',
                color: 'white',
                minHeight: '48px !important',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px',
                '&:hover': {
                  bgcolor: 'success.dark',
                },
                '& .MuiSvgIcon-root': {
                  color: 'white'
                }
              },
              '& .MuiAccordionSummary-content': {
                margin: '8px 0 !important'
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ 
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  justifyContent: 'center'
                }
              }}
            >
              <Typography 
                variant="subtitle1" 
                sx={{ 
                  fontWeight: 600,
                  fontSize: '1.2rem'
                }}
              >
                Advanced Settings
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 4 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      type="number"
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          Prep Time (days)
                          <Tooltip title="Number of days needed to prepare for the event">
                            <HelpOutlineIcon color="action" sx={{ cursor: 'help', fontSize: 18 }} />
                          </Tooltip>
                        </Box>
                      }
                      value={event.prepTime || ''}
                      onChange={(e) => setEvent({ ...event, prepTime: e.target.value })}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      type="date"
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          Earliest Event Date
                          <Tooltip title="The earliest date this event can be scheduled (defaults to today)">
                            <HelpOutlineIcon color="action" sx={{ cursor: 'help', fontSize: 18 }} />
                          </Tooltip>
                        </Box>
                      }
                      value={event.earliestEventDate || today}
                      onChange={(e) => setEvent({ ...event, earliestEventDate: e.target.value })}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      type="date"
                      label={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          Latest Event Date (Optional)
                          <Tooltip title="The latest date this event can be scheduled (optional)">
                            <HelpOutlineIcon color="action" sx={{ cursor: 'help', fontSize: 18 }} />
                          </Tooltip>
                        </Box>
                      }
                      value={event.latestEventDate || ''}
                      onChange={(e) => setEvent({ ...event, latestEventDate: e.target.value })}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Box>
                </Box>

                <Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <Typography variant="subtitle1">Days of the Week</Typography>
                    <Tooltip title="If your event can only be hosted on specific days of the week, please select one or more days below.">
                      <HelpOutlineIcon color="action" sx={{ cursor: 'help', fontSize: 18 }} />
                    </Tooltip>
                  </Box>
                  <Grid container spacing={1} sx={{ mb: 2 }}>
                    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                      <Grid item xs={12/7} key={day}>
                        <Paper
                          elevation={event.daysOfWeek?.includes(day) ? 3 : 1}
                          sx={{
                            cursor: 'pointer',
                            textAlign: 'center',
                            py: 1,
                            bgcolor: event.daysOfWeek?.includes(day) ? 'primary.main' : 'grey.100',
                            color: event.daysOfWeek?.includes(day) ? 'white' : 'text.primary',
                            transition: 'all 0.2s',
                            '&:hover': {
                              bgcolor: event.daysOfWeek?.includes(day) ? 'primary.dark' : 'grey.200',
                            },
                          }}
                          onClick={() => {
                            const updatedDays = event.daysOfWeek?.includes(day)
                              ? (event.daysOfWeek || []).filter(d => d !== day)
                              : [...(event.daysOfWeek || []), day];
                            setEvent({ ...event, daysOfWeek: updatedDays });
                          }}
                        >
                          <Typography variant="body2">{day}</Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="outlined" onClick={onGoBack}>
              Go Back
            </Button>
            <Button 
              variant="contained" 
              type="submit"
              disabled={loading}
            >
              {submitButtonText || 'Submit'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EventForm;

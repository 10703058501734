import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useUser } from '../contexts/UserContext';
import axiosInstance from '../utils/axiosConfig';

function NavBar() {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const isLoggedIn = !!localStorage.getItem('token');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchUserData = async () => {
      if (localStorage.getItem('token') && !user) {
        try {
          const response = await axiosInstance.get('/auth/profile');
          setUser(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };
    fetchUserData();
  }, [setUser, user]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/');
    window.location.reload();
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate('/profile');
    handleMenuClose();
  };

  const navigationItems = [
    { text: 'Events', path: '/events' },
    { 
      text: 'Create Event', 
      path: isLoggedIn ? '/events/create' : '/register',
      requiresAuth: false 
    },
    { text: 'My Events', path: '/my-events', requiresAuth: true },
  ];

  const mobileNavigationItems = [
    { 
      text: 'Create Event', 
      path: '/events/create', 
      requiresAuth: true 
    },
    { text: 'Explore Events', path: '/events', requiresAuth: false },
    { text: 'My Events', path: '/my-events', requiresAuth: true },
    { text: 'Login', path: '/login', requiresAuth: false, showWhenLoggedOut: true },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      {isLoggedIn && (
        <Box sx={{ py: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Avatar 
            sx={{ 
              width: 64, 
              height: 64, 
              margin: '0 auto',
              mb: 1
            }}
            src={user?.avatar || `https://ui-avatars.com/api/?name=${user?.username || 'U'}&background=random`}
            alt={user?.username || 'User'}
          />
          <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
            {user?.username || 'User'}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
            {user?.email || ''}
          </Typography>
          <Link
            component={Link}
            to="/profile"
            sx={{
              fontSize: '0.75rem',
              color: 'primary.main',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              },
              mt: 1,
              display: 'block'
            }}
          >
            Profile Settings
          </Link>
        </Box>
      )}
      <List>
        {mobileNavigationItems.map((item) => (
          ((isLoggedIn && !item.showWhenLoggedOut) || (!isLoggedIn && item.showWhenLoggedOut) || (!item.requiresAuth && !item.showWhenLoggedOut)) && (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                sx={{
                  textAlign: 'center',
                  ...(item.text === 'Create Event' && {
                    bgcolor: 'success.main',
                    color: 'white',
                    mx: 4,
                    my: 2,
                    py: 0.5,
                    borderRadius: 1,
                    '&:hover': {
                      bgcolor: 'success.dark',
                    },
                  }),
                }}
                onClick={() => navigate(item.path)}
              >
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          )
        ))}
      </List>
      {isLoggedIn && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'text.secondary', mt: 2 }}>
          <Link
            component="button"
            onClick={handleLogout}
            sx={{
              textDecoration: 'none',
              color: 'text.secondary',
              background: 'none',
              border: 'none',
              padding: 0,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Logout
          </Link>
        </Box>
      )}
    </Box>
  );

  return (
    <AppBar position="static" sx={{ bgcolor: 'white' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, cursor: 'pointer', color: 'text.primary' }}
            onClick={() => navigate('/')}
          >
            Eventfully
          </Typography>

          {/* Desktop Navigation */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 2 }}>
            {!isLoggedIn ? (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/events"
                  sx={{ 
                    textTransform: 'none', 
                    color: 'text.primary',
                    fontSize: '1.1rem'
                  }}
                >
                  Explore Events
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/login"
                  sx={{ 
                    bgcolor: 'success.main',
                    color: 'white',
                    px: 2,
                    textTransform: 'none',
                    fontSize: '1.1rem',
                    '&:hover': {
                      bgcolor: 'success.dark',
                    },
                  }}
                >
                  Login
                </Button>
              </>
            ) : (
              <>
                <Button
                  color="inherit"
                  component={Link}
                  to="/events/create"
                  sx={{ 
                    bgcolor: 'success.main',
                    color: 'white',
                    px: 2,
                    textTransform: 'none',
                    fontSize: '1.1rem',
                    '&:hover': {
                      bgcolor: 'success.dark',
                    },
                  }}
                >
                  Create Event
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/events"
                  sx={{ 
                    textTransform: 'none', 
                    color: 'text.primary',
                    fontSize: '1.1rem'
                  }}
                >
                  Explore Events
                </Button>
                <Button
                  color="inherit"
                  component={Link}
                  to="/my-events"
                  sx={{ 
                    textTransform: 'none', 
                    color: 'text.primary',
                    fontSize: '1.1rem'
                  }}
                >
                  My Events
                </Button>
              </>
            )}
            {isLoggedIn && (
              <>
                <IconButton
                  onClick={handleAvatarClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar 
                    sx={{ width: 32, height: 32 }}
                    src={user?.avatar || `https://ui-avatars.com/api/?name=${user?.username || 'U'}&background=random`}
                    alt={user?.username || 'User'}
                  />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  onClick={handleMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </Box>

          {/* Mobile Navigation */}
          <IconButton
            sx={{ color: 'text.primary', display: { md: 'none' } }}
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
}

export default NavBar; 
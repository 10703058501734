import React from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
} from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Terms of Service
        </Typography>
        
        <Paper elevation={2} sx={{ p: 4 }}>
          <Box component="section" sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              1. Acceptance of Terms
            </Typography>
            <Typography variant="body1" paragraph>
              By accessing and using this website, you accept and agree to be bound 
              by the terms and provision of this agreement.
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box component="section" sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              2. User Account
            </Typography>
            <Typography variant="body1" paragraph>
              To use certain features of the platform, you must register for an account. 
              You agree to:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Provide accurate information" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Maintain the security of your account" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Promptly update any changes to your information" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Accept responsibility for all activities under your account" />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box component="section">
            <Typography variant="h5" gutterBottom>
              3. Content Guidelines
            </Typography>
            <Typography variant="body1" paragraph>
              When creating events and uploading content, you agree not to:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Post false or misleading information" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Violate any applicable laws or regulations" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Infringe on others' intellectual property rights" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Share inappropriate or offensive content" />
              </ListItem>
            </List>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default TermsOfService; 
import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
} from '@mui/material';

const GoogleCallback = () => {
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('Processing authentication...');

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const error = urlParams.get('error');
        const state = urlParams.get('state');
        
        console.log('OAuth Callback Debug:', {
          hasToken: !!token,
          hasError: !!error,
          hasState: !!state,
          savedState: localStorage.getItem('oauth_state')
        });

        if (error) {
          throw new Error(`OAuth error: ${error}`);
        }

        // More lenient state checking in development
        const savedState = localStorage.getItem('oauth_state');
        if (process.env.NODE_ENV === 'production' && state !== savedState) {
          throw new Error('Invalid OAuth state');
        }
        
        if (!token) {
          throw new Error('No token received');
        }

        // Store token and redirect
        localStorage.setItem('token', token);
        setStatus('Authentication successful! Redirecting...');
        
        // Add a small delay before redirect
        setTimeout(() => {
          window.location.href = '/profile';
        }, 1000);

      } catch (error) {
        console.error('OAuth callback error:', error);
        setError(error.message);
        setStatus('Authentication failed');
        
        // Longer delay for error state
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      } finally {
        localStorage.removeItem('oauth_state'); // Clean up
      }
    };
    
    handleCallback();
  }, []);

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
          {error ? (
            <>
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
              <Typography variant="body2" color="text.secondary">
                Redirecting to login page...
              </Typography>
            </>
          ) : (
            <>
              <CircularProgress sx={{ mb: 3 }} />
              <Typography variant="h6" component="h1">
                {status}
              </Typography>
            </>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default GoogleCallback; 
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Tabs,
  Tab,
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  CircularProgress,
  Grid,
  Button,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axiosInstance from '../utils/axiosConfig';
import { getImageUrl } from '../utils/imageUtils';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

function MyEvents() {
  const [activeTab, setActiveTab] = useState('upcoming');
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEvents();
  }, [activeTab]);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      // Ensure authorization header is set
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      let endpoint = '';
      switch(activeTab) {
        case 'upcoming':
          endpoint = '/events/my-events/upcoming';
          break;
        case 'created':
          endpoint = '/events/my-events/created';
          break;
        case 'interested':
          endpoint = '/events/my-events/interested';
          break;
        default:
          endpoint = '/events/my-events/upcoming';
      }

      const response = await axiosInstance.get(endpoint);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      // Handle unauthorized access
      if (error.response?.status === 401) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        My Events
      </Typography>
      
      <StyledTabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab value="upcoming" label="Upcoming" />
        <Tab value="created" label="Created" />
        <Tab value="interested" label="Interested In" />
      </StyledTabs>

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : events.length === 0 ? (
        <Box textAlign="center" my={4}>
          <Typography color="text.secondary">
            No events found for this category.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {events.map(event => (
            <Grid item xs={12} sm={6} md={4} key={event._id}>
              <Card elevation={2}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                  },
                }}
              >
                <CardActionArea component={Link} to={`/events/${event._id}`}>
                  {event.flyerImage && (
                    <CardMedia
                      component="img"
                      height="200"
                      image={getImageUrl(event.flyerImage)}
                      alt="Event flyer"
                      onError={(e) => {
                        if (!e.target.hasAttribute('data-error-handled')) {
                          e.target.setAttribute('data-error-handled', 'true');
                          e.target.src = '/default-image.jpg';
                        }
                      }}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6" component="h2" gutterBottom>
                      {event.title}
                    </Typography>
                    
                    {/* Event Status */}
                    <Typography 
                      variant="body2" 
                      color={
                        event.status === 'Cancelled' 
                          ? "error.main"
                          : event.status === 'Planning'
                            ? "warning.main"
                            : event.status === 'Completed'
                              ? "text.secondary"
                              : "success.main"
                      }
                      sx={{ mb: 2, fontWeight: 'medium' }}
                    >
                      {event.status}
                    </Typography>

                    {/* Progress */}
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        RSVPs: {event.rsvps?.length || 0} / {event.maxCapacity}
                      </Typography>
                      <LinearProgress 
                        variant="determinate" 
                        value={(event.rsvps?.length || 0) / event.maxCapacity * 100}
                        sx={{ mt: 1, height: 6, borderRadius: 1 }}
                      />
                    </Box>

                    {/* Description */}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}

export default MyEvents;
// EventDetails.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import axiosInstance from '../utils/axiosConfig';
import { getImageUrl } from '../utils/imageUtils';
import { config } from '../config/config';
import EventDetailsCard from './EventDetailsCard';
import { debugLog, authDebug, apiDebug } from '../utils/debugUtils';
import ImageUpload from './ImageUpload';

function EventDetails() {
  const [event, setEvent] = useState(null);
  const [isRSVPed, setIsRSVPed] = useState(false);
  const [rsvpCount, setRsvpCount] = useState(0);
  const [isOwner, setIsOwner] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { id: eventId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Add useEffect to check authentication status
  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const isUserAuthenticated = Boolean(token && user?._id);
    setIsAuthenticated(isUserAuthenticated);
    
    authDebug(user);
  }, []);

  // Fetch event data including RSVP status
  const fetchEventData = async () => {
    if (!eventId) {
      setError('No event ID provided');
      setLoading(false);
      return;
    }

    try {
      const response = await axiosInstance.get(`/events/${eventId}`);
      apiDebug(`/events/${eventId}`, response);

      if (!response.data) {
        throw new Error('No event data received');
      }

      // Set event data first
      setEvent(response.data);
      setRsvpCount(response.data.rsvps?.length || 0);

      // Then handle authentication and ownership
      const token = localStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user') || '{}');

      if (token && user._id && response.data) {
        setIsAuthenticated(true);
        const eventOwnerId = response.data.owner?._id || response.data.owner;
        const currentUserId = user._id;
        
        // Check RSVP status regardless of ownership
        setIsRSVPed(response.data.rsvps?.some(rsvp => {
            const rsvpId = typeof rsvp === 'string' ? rsvp : rsvp._id;
            const userId = String(currentUserId);
            return String(rsvpId) === userId;
        }) || false);
        
        // Set owner status separately
        const isEventOwner = String(eventOwnerId) === String(currentUserId);
        setIsOwner(isEventOwner);
      }

    } catch (error) {
      debugLog('Error', {
        message: error.message,
        endpoint: `/events/${eventId}`,
        timestamp: new Date().toISOString()
      });

      if (error.response?.status === 404) {
        setError('Event not found');
      } else {
        setError('Unable to load event details. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEventData();
  }, [eventId]);

  // Show loading state first
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  // Then show error if any
  if (error) {
    return (
      <Box textAlign="center" py={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  // Finally, only render event details if we have event data
  if (!event) {
    return (
      <Box textAlign="center" py={4}>
        <Typography color="text.secondary">Event not found</Typography>
      </Box>
    );
  }

  const handleRSVP = async () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      // Explicitly set the authorization header for this request
      const response = await axiosInstance.post(`/events/${eventId}/rsvp`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.data && response.data.attendees) {
        setIsRSVPed(true);
        setRsvpCount(response.data.attendees.length);
        setEvent(prev => ({
          ...prev,
          rsvps: response.data.attendees
        }));
      }
    } catch (error) {
      console.error('Error updating RSVP:', error);
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
      } else {
        setError(error.response?.data?.message || 'Error updating RSVP');
      }
    }
  };

  const handleUnRSVP = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      const response = await axiosInstance.delete(`/events/${eventId}/rsvp`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (response.data && response.data.attendees) {
        setIsRSVPed(false);
        setRsvpCount(response.data.attendees.length);
        setEvent(prev => ({
          ...prev,
          rsvps: response.data.attendees
        }));
      }
    } catch (error) {
      console.error('Error removing RSVP:', error);
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        navigate('/login');
      } else {
        setError(error.response?.data?.message || 'Error removing RSVP');
      }
    }
  };

  const handleEdit = () => {
    navigate(`/events/${eventId}/edit`);
  };

  // Format dates for display
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getEventImage = (event) => {
    if (!event.flyerImage) {
      return '/images/default-image.jpg';
    }
    
    // If it's already a full URL, return it
    if (event.flyerImage.startsWith('http')) {
      return event.flyerImage;
    }
    
    // Otherwise, construct the URL using the API URL
    return `${config.API_URL}/uploads/${event.flyerImage}`;
  };

  const EventImage = ({ imagePath, title }) => {
    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 3;

    const handleImageError = (e) => {
      console.error('Image load error:', {
        src: e.target.src,
        originalSrc: imagePath,
        retryCount,
        environment: import.meta.env.MODE
      });

      if (retryCount < maxRetries) {
        setRetryCount(prev => prev + 1);
        // Add a small delay before retry
        setTimeout(() => {
          e.target.src = getImageUrl(imagePath);
        }, 1000 * retryCount); // Exponential backoff
      } else {
        e.target.onerror = null; // Prevent further retries
        e.target.src = '/images/default-image.jpg';
      }
    };

    return (
      <img 
        src={getImageUrl(imagePath)}
        alt={title}
        onError={handleImageError}
        className="event-image"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    );
  };

  const handleImageUpdate = async (file) => {
    try {
      console.log('Starting image upload:', { fileName: file.name, fileSize: file.size });
      
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folder', 'event-flyers');
      formData.append('bucket', 'eventfully-uploads');
      
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found');
      }

      // Use the config's UPLOAD_URL instead of constructing it
      const uploadResponse = await axiosInstance.post(config.UPLOAD_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        },
        timeout: 60000,
        maxBodyLength: Infinity,
        maxContentLength: Infinity
      });

      // Add detailed error logging
      console.log('Upload attempt details:', {
        url: config.UPLOAD_URL,
        fileSize: file.size,
        fileName: file.name,
        responseStatus: uploadResponse?.status
      });

      if (!uploadResponse.data || !uploadResponse.data.imageUrl) {
        console.error('Invalid upload response:', uploadResponse);
        throw new Error('Invalid upload response');
      }

      const imageUrl = uploadResponse.data.imageUrl;
      console.log('Successfully uploaded image:', imageUrl);

      const updateResponse = await axiosInstance.patch(`/events/${eventId}`, {
        flyerImage: imageUrl
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (updateResponse.data) {
        setEvent(prev => ({
          ...prev,
          flyerImage: imageUrl
        }));
        setImagePreview(imageUrl);
      }
    } catch (error) {
      handleUploadError(error);
      throw error;
    }
  };

  const handleUploadError = (error) => {
    console.error('Upload error details:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      config: {
        url: error.config?.url,
        headers: error.config?.headers,
        method: error.config?.method
      },
      mode: import.meta.env.MODE,
      apiUrl: config.API_URL,
      uploadUrl: config.UPLOAD_URL
    });
    
    if (error.response?.status === 500) {
      throw new Error(`Server error during upload: ${error.response?.data?.message || 'Unknown error'}`);
    } else if (error.response?.status === 413) {
      throw new Error('File size too large. Maximum size is 20MB.');
    } else {
      throw new Error(`Upload failed: ${error.message}`);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      {/* Title and Back Button Section */}
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center" 
        mb={4}
      >
        <Typography 
          variant="h2" 
          component="h1" 
          sx={{ 
            fontWeight: 'bold',
          }}
        >
          {event.title}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => navigate('/events')}
          startIcon={<ArrowBackIcon />}
        >
          Back to Events
        </Button>
      </Box>

      {/* Action buttons */}
      <Box 
        display="flex" 
        justifyContent="space-between" 
        alignItems="center" 
        mb={4}
      >
        {isOwner && (
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={() => navigate(`/events/${eventId}/edit`)}
          >
            Edit Event
          </Button>
        )}
      </Box>

      {/* Title and Image Section */}
      <Box mb={6}>
        {event.flyerImage && (
          <Card elevation={3}>
            <CardMedia
              component="img"
              image={getImageUrl(event.flyerImage)}
              alt={event.title}
              sx={{ 
                height: 500, 
                objectFit: 'cover',
                '@media (max-width: 600px)': {
                  height: 300
                }
              }}
              onError={(e) => {
                if (!e.target.hasAttribute('data-error-handled')) {
                  e.target.setAttribute('data-error-handled', 'true');
                  e.target.src = '/default-image.jpg';
                }
              }}
            />
          </Card>
        )}
      </Box>

      {/* Main Content Grid */}
      <Grid container spacing={3}>
        {/* Left Column - Main Details */}
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
            {/* Event Meta Information Card */}
            <Paper elevation={2} sx={{ p: 3, bgcolor: 'background.paper' }}>
              <Grid container spacing={3}>
                {/* Event Hours */}
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Proposed Time
                    </Typography>
                    <Typography variant="body1">
                      {event.startTime && new Date(`2000-01-01T${event.startTime}`).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true
                      })} 
                      {' - '}
                      {event.endTime && new Date(`2000-01-01T${event.endTime}`).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true
                      })}
                    </Typography>
                  </Box>
                </Grid>

                {/* Event Period */}
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Event Window
                    </Typography>
                    <Typography variant="body1">
                      {formatDate(event.earliestEventDate)}
                      {event.latestEventDate && event.latestEventDate !== event.earliestEventDate && (
                        <>
                          {' - '}
                          {formatDate(event.latestEventDate)}
                        </>
                      )}
                    </Typography>
                  </Box>
                </Grid>

                {/* Pricing */}
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography variant="subtitle2" color="text.secondary" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Ticket Price
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                      {event.status === 'Scheduled' ? (
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            color: 'text.primary',
                            fontWeight: 'bold'
                          }}
                        >
                          ${event.finalPrice}
                        </Typography>
                      ) : (
                        <>
                          <Typography 
                            variant="body1" 
                            sx={{ 
                              color: 'text.secondary',
                              textDecoration: 'line-through'
                            }}
                          >
                            ${event.finalPrice}
                          </Typography>
                          <Typography 
                            variant="h6" 
                            color="success.main" 
                            sx={{ fontWeight: 'bold' }}
                          >
                            ${event.earlyBirdPrice}
                          </Typography>
                        </>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Paper>

            {/* Description Card */}
            <Paper elevation={2} sx={{ p: 3, bgcolor: 'background.paper' }}>
              <Typography variant="h6" gutterBottom>
                Description
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ lineHeight: 1.8 }}
                dangerouslySetInnerHTML={{ __html: event.description }}
              />
            </Paper>
          </Stack>
        </Grid>

        {/* Right Column - Meta Info */}
        <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <Paper 
              elevation={2} 
              sx={{ 
                p: 3, 
                bgcolor: 'background.paper'
              }}
            >
              <Typography variant="h6" gutterBottom>
                Event Status
              </Typography>

              {/* Event Status */}
              <Box sx={{ mb: 3 }}>
              <Typography 
                variant="body1" 
                color={
                  event.status === 'Cancelled' 
                    ? "error.main"
                    : event.status === 'Planning'
                      ? "warning.main"
                      : event.status === 'Completed'
                        ? "text.secondary"
                        : "success.main"
                    }
                  sx={{ fontWeight: 'medium' }}
                >
                  {event.status}
                </Typography>
              </Box>
              
              {/* Capacity Progress Bar */}
              <Box sx={{ position: 'relative', mt: 4, mb: 2 }}>
                {/* Go Live Text */}
                <Typography variant="caption" color="success.main" sx={{ 
                  position: 'absolute',
                  left: `${(event.minGoLiveCapacity / event.maxCapacity) * 100}%`,
                  top: -20,
                  transform: 'translateX(-50%)'
                }}>
                  Go Live: {event.minGoLiveCapacity}
                </Typography>

                <Box sx={{ 
                  width: '100%', 
                  height: '40px', 
                  bgcolor: 'grey.200',
                  borderRadius: 1,
                  position: 'relative',
                  overflow: 'hidden'
                }}>
                  {/* Go Live Marker */}
                  <Box sx={{
                    position: 'absolute',
                    left: `${(event.minGoLiveCapacity / event.maxCapacity) * 100}%`,
                    top: 0,
                    bottom: 0,
                    width: 2,
                    bgcolor: 'success.main',
                    zIndex: 2
                  }} />
                  
                  {/* Progress Fill */}
                  <Box sx={{
                    width: `${(rsvpCount / event.maxCapacity) * 100}%`,
                    height: '100%',
                    bgcolor: 'primary.main',
                    transition: 'width 0.3s ease'
                  }} />
                </Box>

                {/* Markers */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Typography variant="caption">
                    Min: {event.minCapacity}
                  </Typography>
                  <Typography variant="caption">
                    Max: {event.maxCapacity}
                  </Typography>
                </Box>
              </Box>

              <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', mb: 3 }}>
                {Math.max(0, event.minGoLiveCapacity - rsvpCount)} more RSVPs needed before event goes live
              </Typography>

              <Divider sx={{ my: 2 }} />

              {isAuthenticated && !isOwner ? (
                <Button
                  variant="contained"
                  color={isRSVPed ? "error" : "primary"}
                  fullWidth
                  onClick={isRSVPed ? handleUnRSVP : handleRSVP}
                >
                  {isRSVPed ? 'Cancel RSVP' : 'RSVP'}
                </Button>
              ) : !isAuthenticated ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => navigate('/login')}
                >
                  Login to RSVP
                </Button>
              ) : null}

              <Divider sx={{ my: 3 }} />

              {/* Interested Section */}
              <Typography variant="h6" gutterBottom>
                Interested ({rsvpCount})
              </Typography>

              {rsvpCount === 0 ? (
                <Typography variant="body1">
                  No interest yet. Be the first!
                </Typography>
              ) : (
                <List>
                  {event.rsvps?.map((user, index) => (
                    <ListItem key={user._id || `user-${index}`} disablePadding>
                      <ListItemText 
                        primary={user.username || 'Anonymous'} 
                        sx={{ py: 0.5 }}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EventDetails;

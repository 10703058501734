export const getImageUrl = (imagePath, type = 'event-flyers') => {
  if (!imagePath) return '/images/default-image.jpg';
  
  // If it's already a full URL, parse it to determine the correct type
  if (imagePath.startsWith('http')) {
    // Check if the URL contains user-avatars
    if (imagePath.includes('user-avatars')) {
      return imagePath;
    }
    // Check if it's in the old format and needs type correction
    if (imagePath.includes('eventfully-uploads')) {
      const pathParts = imagePath.split('eventfully-uploads/');
      if (pathParts[1] && !pathParts[1].startsWith(type)) {
        return imagePath.replace('eventfully-uploads/', `eventfully-uploads/${type}/`);
      }
    }
    return imagePath;
  }
  
  // For development environment
  if (import.meta.env.MODE === 'development') {
    const apiUrl = 'http://localhost:3001';
    const cleanPath = imagePath.startsWith('/') ? imagePath : `/${imagePath}`;
    return `${apiUrl}${cleanPath}`;
  }
  
  // For production environment
  const cleanPath = imagePath.startsWith('/') ? imagePath.slice(1) : imagePath;
  // Ensure we're using the correct folder structure
  return `https://storage.googleapis.com/eventfully-uploads/${type}/${cleanPath}`;
}; 
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './theme/theme';
import App from './App.jsx';
import { debugLog } from './utils/debugUtils';
import { config } from './config/config';

debugLog('Application Start', {
  apiUrl: import.meta.env.VITE_API_URL,
  nodeEnv: import.meta.env.NODE_ENV,
  config
});

const root = ReactDOM.createRoot(document.getElementById('root'));
if (!root) {
  console.error('Root element not found');
} else {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter future={{ 
          v7_startTransition: true,
          v7_relativeSplatPath: true 
        }}>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
}
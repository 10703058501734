const NODE_ENV = import.meta.env.NODE_ENV;
const API_URL = import.meta.env.VITE_API_URL;

if (!API_URL) {
  console.error('VITE_API_URL is not defined');
  throw new Error('VITE_API_URL is not defined');
}

const formatApiUrl = (url) => {
  if (!url) return '';
  
  url = url.replace(/\/+$/, '');
  
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    const protocol = import.meta.env.MODE === 'production' ? 'https://' : 'http://';
    url = `${protocol}${url}`;
  }
  
  return url;
};

export const config = {
  API_URL: formatApiUrl(API_URL),
  UPLOAD_URL: `${formatApiUrl(API_URL)}/upload`,
  NODE_ENV,
  isProduction: import.meta.env.MODE === 'production'
};

import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  IconButton,
  InputAdornment,
  Divider,
  Grid,
  Avatar,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import GoogleIcon from '@mui/icons-material/Google';
import axiosInstance from '../utils/axiosConfig';
import AvatarUpload from './AvatarUpload';
import { useUser } from '../contexts/UserContext';

function Profile() {
  const { setUser } = useUser();
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    avatar: null,
    isGoogleConnected: false,
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    confirm: false
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get('/auth/profile', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        
        setUserData(prevData => ({
          ...prevData,
          ...response.data,
          currentPassword: '',
          newPassword: '',
          confirmPassword: ''
        }));
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load user profile');
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value
    });
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.put('/auth/profile', {
        username: userData.username,
        email: userData.email
      });
      setMessage('Profile updated successfully');
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'Error updating profile');
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (userData.newPassword !== userData.confirmPassword) {
      setError('New passwords do not match');
      return;
    }
    try {
      await axiosInstance.put('/auth/password', {
        currentPassword: userData.currentPassword,
        newPassword: userData.newPassword
      });
      setMessage('Password updated successfully');
      setError('');
      setUserData(prev => ({
        ...prev,
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      }));
    } catch (err) {
      setError(err.response?.data?.message || 'Error updating password');
    }
  };

  const handleGoogleConnect = async () => {
    if (userData.isGoogleConnected) {
      try {
        await axiosInstance.delete('/auth/google/disconnect');
        setUserData(prev => ({ ...prev, isGoogleConnected: false }));
        setMessage('Google Calendar disconnected successfully');
      } catch (err) {
        setError('Failed to disconnect Google Calendar');
      }
    } else {
      window.location.href = `${import.meta.env.VITE_API_URL}/auth/google`;
    }
  };

  const handleAvatarSave = async (blob) => {
    try {
      const formData = new FormData();
      formData.append('file', blob, 'avatar.jpg');

      const response = await axiosInstance.post('/upload/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.data.imageUrl) {
        setUserData(prev => ({
          ...prev,
          avatar: response.data.imageUrl
        }));
        setUser(prev => ({
          ...prev,
          avatar: response.data.imageUrl
        }));
        setMessage('Avatar updated successfully');
        
        await axiosInstance.put('/auth/profile', {
          avatar: response.data.imageUrl
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
      }
    } catch (err) {
      console.error('Avatar upload error:', err);
      setError('Failed to update avatar');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Box>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>Profile Information</Typography>
              <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                <AvatarUpload
                  currentImage={userData.avatar || `https://ui-avatars.com/api/?name=${userData.username}&background=random`}
                  onSave={handleAvatarSave}
                />
              </Box>
              <form onSubmit={handleUpdateProfile}>
                <TextField
                  fullWidth
                  label="Username"
                  name="username"
                  value={userData.username}
                  onChange={handleChange}
                  margin="normal"
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={userData.email}
                  onChange={handleChange}
                  margin="normal"
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Update Profile
                </Button>
              </form>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>Change Password</Typography>
              <form onSubmit={handleUpdatePassword}>
                {['current', 'new', 'confirm'].map((type) => (
                  <TextField
                    key={type}
                    fullWidth
                    label={`${type.charAt(0).toUpperCase() + type.slice(1)} Password`}
                    name={`${type}Password`}
                    type={showPasswords[type] ? 'text' : 'password'}
                    value={userData[`${type}Password`]}
                    onChange={handleChange}
                    margin="normal"
                    InputProps={{
                      endAdornment:
                        <IconButton
                          onClick={() => setShowPasswords({ ...showPasswords, [type]: !showPasswords[type] })}
                          edge="end"
                        >
                          {showPasswords[type] ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    }}
                  />
                ))}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  Update Password
                </Button>
              </form>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper elevation={2} sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>Calendar Integration</Typography>
              <Button
                variant="contained"
                color={userData.isGoogleConnected ? "error" : "success"}
                fullWidth
                startIcon={<GoogleIcon />}
                onClick={handleGoogleConnect}
                sx={{ mt: 2 }}
              >
                {userData.isGoogleConnected ? "Disconnect Google Calendar" : "Connect Google Calendar"}
              </Button>
              {userData.isGoogleConnected && (
                <Typography variant="body1" color="textSecondary" sx={{ mt: 1 }}>
                  ✓ Connected to Google Calendar
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Profile; 
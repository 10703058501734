import React, { useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { config } from '../config/config';
import { debugLog } from '../utils/debugUtils';

const EnvDebugRoute = () => {
  const [debugInfo, setDebugInfo] = React.useState(null);

  useEffect(() => {
    const info = {
      config: {
        API_URL: config.API_URL,
        NODE_ENV: config.NODE_ENV,
        isProduction: config.isProduction,
        baseUrl: config.baseUrl
      },
      importMeta: {
        NODE_ENV: import.meta.env.NODE_ENV,
        VITE_API_URL: import.meta.env.VITE_API_URL,
        MODE: import.meta.env.MODE
      },
      window: {
        location: window.location.href,
        origin: window.location.origin
      },
      auth: {
        hasToken: Boolean(localStorage.getItem('token')),
        user: JSON.parse(localStorage.getItem('user') || '{}')
      },
      logs: window._debug_logs || []
    };
    setDebugInfo(info);
  }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Environment Debug Information
        </Typography>

        {!debugInfo ? (
          <Box display="flex" justifyContent="center" py={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Paper elevation={2} sx={{ p: 3 }}>
            {Object.entries(debugInfo).map(([section, data]) => (
              <Accordion key={section} defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>
                    {section}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    component="pre"
                    sx={{
                      p: 2,
                      backgroundColor: 'grey.100',
                      borderRadius: 1,
                      overflow: 'auto',
                      '& code': {
                        fontFamily: 'monospace',
                      }
                    }}
                  >
                    <code>
                      {JSON.stringify(data, null, 2)}
                    </code>
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default EnvDebugRoute; 
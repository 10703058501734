import React, { useState, useRef } from 'react';
import { Box, Button, Dialog, Slider } from '@mui/material';
import AvatarEditor from 'react-avatar-editor';

const AvatarUpload = ({ onSave, currentImage }) => {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const editorRef = useRef(null);

  const handleFileSelect = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setOpen(true);
    }
  };

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob((blob) => {
        onSave(blob);
        setOpen(false);
        setImage(null);
      });
    }
  };

  return (
    <>
      <Box sx={{ position: 'relative', width: 100, height: 100, margin: '0 auto' }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          id="avatar-upload"
        />
        <label htmlFor="avatar-upload">
          <Box
            component="img"
            src={currentImage}
            alt="Profile"
            sx={{
              width: 100,
              height: 100,
              borderRadius: '50%',
              cursor: 'pointer',
              '&:hover': { opacity: 0.8 },
            }}
          />
        </label>
      </Box>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <Box sx={{ p: 2, width: 400 }}>
          <AvatarEditor
            ref={editorRef}
            image={image}
            width={250}
            height={250}
            border={50}
            borderRadius={125}
            color={[0, 0, 0, 0.6]}
            scale={scale}
            position={position}
            onPositionChange={setPosition}
          />
          
          <Box sx={{ px: 3, pb: 2 }}>
            <Slider
              value={scale}
              min={1}
              max={3}
              step={0.01}
              onChange={(_, value) => setScale(value)}
            />
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSave}>Save</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default AvatarUpload; 
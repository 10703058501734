import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  CircularProgress,
  Box,
  Stack,
  LinearProgress,
} from '@mui/material';
import axiosInstance from '../utils/axiosConfig';
import { getImageUrl } from '../utils/imageUtils';

function EventList() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axiosInstance.get('/events');
      setEvents(response.data);
      setError(null);
    } catch (err) {
      setError(err.message);
      console.error('Error fetching events:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" py={4}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Events
      </Typography>

      {events.length === 0 ? (
        <Box textAlign="center" py={4}>
          <Typography color="text.secondary">No events found</Typography>
        </Box>
      ) : (
        <Grid container spacing={3}>
          {events.map(event => (
            <Grid item xs={12} sm={6} md={4} key={event._id}>
              <Card 
                elevation={2}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                  },
                }}
              >
                <CardActionArea component={Link} to={`/events/${event._id}`}>
                  {event.flyerImage && (
                    <CardMedia
                      component="img"
                      height="200"
                      image={getImageUrl(event.flyerImage)}
                      alt="Event flyer"
                      onError={(e) => {
                        if (!e.target.hasAttribute('data-error-handled')) {
                          e.target.setAttribute('data-error-handled', 'true');
                          e.target.src = '/default-image.jpg';
                        }
                      }}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6" component="h2" gutterBottom>
                      {event.title}
                    </Typography>
                    
                    {/* Event Status */}
                    <Typography 
                      variant="body2" 
                      color={
                        event.status === 'Cancelled' 
                          ? "error.main"
                          : event.status === 'Planning'
                            ? "warning.main"
                            : event.status === 'Completed'
                              ? "text.secondary"
                              : "success.main"
                      }
                      sx={{ mb: 2, fontWeight: 'medium' }}
                    >
                      {event.status}
                    </Typography>

                    {/* Only show date and time if event has a confirmed date */}
                    {event.confirmedDate && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                          Date: {new Date(event.confirmedDate).toLocaleDateString()}
                        </Typography>
                        {event.startTime && event.endTime && (
                          <Typography variant="body2" color="text.secondary">
                            Time: {new Date(`2000-01-01T${event.startTime}`).toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true
                            })} 
                            {' - '}
                            {new Date(`2000-01-01T${event.endTime}`).toLocaleTimeString('en-US', {
                              hour: 'numeric',
                              minute: '2-digit',
                              hour12: true
                            })}
                          </Typography>
                        )}
                      </Box>
                    )}

                    {/* Price */}
                    <Box sx={{ mb: 2 }}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {event.status === 'Scheduled' ? (
                          <Typography variant="body2" color="text.secondary">
                            ${event.finalPrice}
                          </Typography>
                        ) : (
                          <>
                            <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                              ${event.finalPrice}
                            </Typography>
                            <Typography variant="body2" color="success.main" sx={{ fontWeight: 'bold' }}>
                              ${event.earlyBirdPrice}
                            </Typography>
                          </>
                        )}
                      </Stack>
                    </Box>

                    {/* Progress */}
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        RSVPs: {event.rsvps?.length || 0} / {event.maxCapacity}
                      </Typography>
                      <LinearProgress 
                        variant="determinate" 
                        value={(event.rsvps?.length || 0) / event.maxCapacity * 100}
                        sx={{ mt: 1, height: 6, borderRadius: 1 }}
                      />
                    </Box>

                    {/* Description */}
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
}

export default EventList;

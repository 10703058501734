import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import CreateEvent from './components/CreateEvent.jsx';
import EventList from './components/EventList.jsx';
import EventDetails from './components/EventDetails.jsx';
import Register from './components/Register.jsx';
import Login from './components/Login.jsx';
import Profile from './components/Profile.jsx';
import MyEvents from './components/MyEvents.jsx';
import EditEvent from './components/EditEvent.jsx';
import ProtectedRoute from './components/ProtectedRoute.jsx';
import Footer from './components/Footer.jsx';
import './styles/App.css';
import ErrorBoundary from './components/ErrorBoundary.jsx';
import './utils/axiosConfig';
import { debugLog, checkApiHealth } from './utils/debugUtils';
import { config } from './config/config';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';
import TermsOfService from './components/TermsOfService.jsx';
import EnvDebugRoute from './components/EnvDebugRoute';
import GoogleCallback from './components/GoogleCallback';
import { UserProvider } from './contexts/UserContext';

debugLog('App Initialization', {
  NODE_ENV: import.meta.env.NODE_ENV,
  VITE_API_URL: import.meta.env.VITE_API_URL,
  timestamp: new Date().toISOString()
});

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <UserProvider>
      <div className="App">
        <ErrorBoundary>
          <NavBar onMenuClick={() => setIsMobileMenuOpen(true)} />
          <div className="content">
            <Routes>
              <Route path="/debug" element={<EnvDebugRoute />} />
              <Route path="/" element={<EventList />} />
              <Route path="/events" element={<EventList />} />
              <Route path="/events/:id" element={<EventDetails />} />
              <Route path="/register" element={<Register />} />
              <Route path="/login" element={<Login />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/my-events" element={<MyEvents />} />
              <Route path="/events/:id/edit" element={<EditEvent />} />
              <Route path="/events/create" element={
                <ProtectedRoute>
                  <CreateEvent />
                </ProtectedRoute>
              } />
              <Route 
                path="/auth/google/callback" 
                element={<GoogleCallback />} 
              />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
            </Routes>
          </div>
          <Footer />
        </ErrorBoundary>
      </div>
    </UserProvider>
  );
}

export default App;

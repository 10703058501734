import React from 'react';
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
} from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        
        <Paper elevation={2} sx={{ p: 4 }}>
          <Box component="section" sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              1. Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
              We collect information that you provide directly to us, including:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Name and email address when you create an account" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Profile information you provide" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Event details and images you upload" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Calendar information when you connect Google Calendar" />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box component="section" sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              2. How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We use the information we collect to:
            </Typography>
            <List>
              <ListItem>
                <ListItemText primary="Provide and maintain our services" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Send you notifications about events" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Improve and optimize our platform" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Protect against fraud and unauthorized access" />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box component="section" sx={{ mb: 4 }}>
            <Typography variant="h5" gutterBottom>
              3. Data Storage and Security
            </Typography>
            <Typography variant="body1" paragraph>
              We implement appropriate security measures to protect your personal information. 
              Your data is stored securely in our database and we use industry-standard 
              encryption for data transmission.
            </Typography>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Box component="section">
            <Typography variant="h5" gutterBottom>
              4. Contact Us
            </Typography>
            <Typography variant="body1">
              If you have any questions about this Privacy Policy, please contact us at 
              support@eventfully.com
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy; 
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosConfig';
import EventForm from './EventForm';
import Loading from './Loading';
import { config } from '../config/config';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';

function EditEvent() {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('user'));
        
        if (!token || !userData) {
          navigate('/login');
          return;
        }

        const response = await axiosInstance.get(`/events/${id}`);

        if (!response.data) {
          navigate('/events');
          return;
        }

        // Check if the current user is the owner
        if (response.data.owner._id !== userData._id) {
          console.log('Unauthorized access attempt to edit event');
          navigate(`/events/${id}`);
          return;
        }

        setEvent(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching event:', error);
        navigate('/events');
      }
    };

    fetchEvent();
  }, [id, navigate]);

  const handleSubmit = async (eventData) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      // Handle image file separately
      if (eventData.imageFile) {
        const imageFormData = new FormData();
        imageFormData.append('file', eventData.imageFile);
        imageFormData.append('folder', 'event-flyers');
        imageFormData.append('bucket', 'eventfully-uploads');
        
        console.log('Attempting upload to:', config.UPLOAD_URL);

        const uploadResponse = await axiosInstance.post(config.UPLOAD_URL, imageFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          },
          timeout: 60000,
          maxBodyLength: Infinity,
          maxContentLength: Infinity
        }).catch(error => {
          console.error('Upload error details:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status,
            url: config.UPLOAD_URL
          });
          throw error;
        });

        if (!uploadResponse.data || !uploadResponse.data.imageUrl) {
          throw new Error('Invalid upload response');
        }

        eventData.flyerImage = uploadResponse.data.imageUrl;
      }

      // Create the event update data
      const updateData = {};
      
      // Add all fields except imageFile to the update data
      Object.keys(eventData).forEach(key => {
        if (key !== 'imageFile' && eventData[key] !== null && eventData[key] !== '') {
          updateData[key] = eventData[key];
        }
      });

      // Send the update request
      const response = await axiosInstance.put(`/events/${id}`, updateData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data) {
        navigate(`/events/${id}`);
      }
    } catch (error) {
      console.error('Error updating event:', error);
      setError(error.response?.data?.message || 'Failed to update event');
    }
  };

  const handleGoBack = () => {
    navigate(`/events/${id}`);
  };

  if (loading) return <Loading />;
  if (!event) return <div>Event not found</div>;

  return (
    <div className="edit-event-container">
      <h2 className="page-title">Edit Event</h2>
      <EventForm 
        initialData={event}
        onSubmit={handleSubmit}
        submitButtonText="Update Event"
        onGoBack={handleGoBack}
      />
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default EditEvent; 
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Paper
} from '@mui/material';
import EventForm from './EventForm';
import axiosInstance from '../utils/axiosConfig';
import { config } from '../config/config';

function CreateEvent() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }
  }, [navigate]);

  const initialData = {
    title: '',
    description: '',
    startTime: '',
    endTime: '',
    minCapacity: '1',
    maxCapacity: '2',
    minGoLiveCapacity: '1',
    earlyBirdPrice: '',
    finalPrice: '',
    prepTime: '1',
    daysOfWeek: [],
    numberOfDays: '',
    earliestEventDate: '',
    latestEventDate: '',
    flyerImage: null,
    imageFile: null
  };

  const handleFormSubmit = async (eventData) => {
    console.log('Form Data Details:', eventData);
    setLoading(true);
    setError(null);
    
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      // Handle image file separately first
      if (eventData.imageFile) {
        const imageFormData = new FormData();
        imageFormData.append('file', eventData.imageFile);
        imageFormData.append('folder', 'event-flyers');
        imageFormData.append('bucket', 'eventfully-uploads');
        
        console.log('Upload attempt details:', {
          url: config.UPLOAD_URL,
          fileDetails: {
            name: eventData.imageFile.name,
            type: eventData.imageFile.type,
            size: eventData.imageFile.size
          },
          formDataEntries: Array.from(imageFormData.entries()).map(([key, value]) => ({
            key,
            valueType: value instanceof File ? 'File' : typeof value
          })),
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer [REDACTED]'
          }
        });

        const uploadResponse = await axiosInstance.post(config.UPLOAD_URL, imageFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
          },
          timeout: 60000,
          maxBodyLength: Infinity,
          maxContentLength: Infinity
        }).catch(error => {
          console.error('Upload error details:', {
            message: error.message,
            response: error.response?.data,
            status: error.response?.status,
            url: config.UPLOAD_URL
          });
          throw error;
        });

        if (!uploadResponse.data || !uploadResponse.data.imageUrl) {
          throw new Error('Invalid upload response');
        }

        eventData.flyerImage = uploadResponse.data.imageUrl;
      }

      // Create FormData for the rest of the event data
      const formData = new FormData();
      
      // Add all fields to FormData
      Object.keys(eventData).forEach(key => {
        if (key !== 'imageFile' && eventData[key] !== null && eventData[key] !== '') {
          if (key.includes('Date')) {
            const date = new Date(eventData[key]);
            formData.append(key, date.toISOString());
          } else {
            formData.append(key, eventData[key].toString());
          }
        }
      });

      const response = await axiosInstance.post('/events', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });

      navigate(`/events/${response.data._id}`);
    } catch (err) {
      console.error('Error creating event:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        headers: err.response?.headers
      });
      setError(err.response?.data?.message || 'Failed to create event. Please ensure all required fields are filled.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Paper elevation={2} sx={{ p: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Create Event
          </Typography>
          
          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : (
            <EventForm 
              initialData={initialData}
              onSubmit={handleFormSubmit}
              submitButtonText="Create Event"
              onGoBack={() => navigate('/events')}
              loading={loading}
            />
          )}
        </Paper>
      </Box>
    </Container>
  );
}

export default CreateEvent; 
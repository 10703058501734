import React from 'react';
import { Box } from '@mui/material';
import { getImageUrl } from '../utils/imageUtils';

const ImageUpload = ({ onImageSelect, currentImage }) => {
  const handleFileSelect = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) return;
    onImageSelect(file);
  };

  return (
    <Box sx={{ mb: 3, textAlign: 'center' }}>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileSelect}
        style={{ display: 'none' }}
        id="image-upload-input"
      />
      <label htmlFor="image-upload-input" style={{ cursor: 'pointer' }}>
        {currentImage ? (
          <img
            src={currentImage}
            alt="Event preview"
            style={{ 
              width: '100%',
              height: '500px',
              objectFit: 'cover',
              marginBottom: '1rem',
              borderRadius: '8px',
              transition: 'opacity 0.3s ease',
              '&:hover': {
                opacity: 0.8
              }
            }}
          />
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '500px',
              border: '2px dashed #ccc',
              borderRadius: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f5f5f5',
              transition: 'background-color 0.3s ease',
              '&:hover': {
                backgroundColor: '#e0e0e0'
              }
            }}
          >
            <p style={{ color: '#808080' }}>Click to select image</p>
          </Box>
        )}
      </label>
    </Box>
  );
};

export default ImageUpload; 